import TypesenseInstantSearchAdapterExport from "typesense-instantsearch-adapter";
const TypesenseInstantSearchAdapter =
  TypesenseInstantSearchAdapterExport.default ?? TypesenseInstantSearchAdapterExport;

let typesenseSearchKey = "";
let typesenseClusterUrl = "";

if (typeof window !== "undefined" && window.ENV) {
  typesenseSearchKey = window.ENV.TYPESENSE_SEARCH_KEY;
  typesenseClusterUrl = window.ENV.TYPESENSE_CLUSTER_URL;
} else if (typeof ENV !== "undefined") {
  typesenseSearchKey = ENV.TYPESENSE_SEARCH_KEY;
  typesenseClusterUrl = ENV.TYPESENSE_CLUSTER_URL;
}

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: typesenseSearchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: typesenseClusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "title,stringifiedContent",
    query_by_weights: "127,90",
    facet_by: "platforms,tags,agency,postType,status",
    sort_by: "publishedAt:desc",
    limit: 24,
    exclude_fields: "embedding",
  },
});

const homeInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: typesenseSearchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: typesenseClusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "title",
    facet_by: "platforms,tags,agency,postType,status",
    sort_by: "publishedAt:desc",
    limit: 15,
    exclude_fields: "embedding",
  },
});

const recommendationsInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: typesenseSearchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: typesenseClusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "embedding",
    sort_by: "publishedAt:desc",
    limit: 8,
    exclude_fields: "embedding",
  },
});

const typesenseSuggestionsInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: typesenseSearchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: typesenseClusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    query_by: "title",
    sort_by: "publishedAt:desc",
    facet_by: "status",
    limit: 5,
    facet_query: "status:published",
    per_page: 5,
    exclude_fields: "embedding",
  },
});

const typesenseRelatedInsightsInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: typesenseSearchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: typesenseClusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "title,stringifiedContent",
    sort_by: "publishedAt:desc",
    facet_by: "status",
    limit: 9,
    facet_query: "status:published",
    per_page: 9,
    exclude_fields: "embedding",
  },
});

const typesenseCompaniesInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: typesenseSearchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: typesenseClusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "legal_name,dba_name,uei",
    facet_by: "socioeconomic_statuses,is_published,state,primary_naics,zip_code",
    limit: 24,
  },
});

const typesenseExploreCompaniesInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: typesenseSearchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: typesenseClusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "legal_name,dba_name,uei",
    facet_by: "socioeconomic_statuses,is_published",
    limit: 8,
  },
});

export const searchClient = typesenseInstantsearchAdapter.searchClient;
export const suggestionsClient = typesenseSuggestionsInstantsearchAdapter.searchClient;
export const relatedInsightsClient = typesenseRelatedInsightsInstantsearchAdapter.searchClient;
export const recommendationsClient = recommendationsInstantsearchAdapter.searchClient;
export const homeClient = homeInstantsearchAdapter.searchClient;
export const companiesClient = typesenseCompaniesInstantsearchAdapter.searchClient;
export const exploreCompaniesClient = typesenseExploreCompaniesInstantsearchAdapter.searchClient;
